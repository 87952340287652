import request from '@/utils/request'


// 查询意见反馈-类型列表
export function listUserFeedbackType(query) {
  return request({
    url: '/platform/user-feedback-type/list',
    method: 'get',
    params: query
  })
}

// 查询意见反馈-类型分页
export function pageUserFeedbackType(query) {
  return request({
    url: '/platform/user-feedback-type/page',
    method: 'get',
    params: query
  })
}

// 查询意见反馈-类型详细
export function getUserFeedbackType(data) {
  return request({
    url: '/platform/user-feedback-type/detail',
    method: 'get',
    params: data
  })
}

// 新增意见反馈-类型
export function addUserFeedbackType(data) {
  return request({
    url: '/platform/user-feedback-type/add',
    method: 'post',
    data: data
  })
}

// 修改意见反馈-类型
export function updateUserFeedbackType(data) {
  return request({
    url: '/platform/user-feedback-type/edit',
    method: 'post',
    data: data
  })
}

// 删除意见反馈-类型
export function delUserFeedbackType(data) {
  return request({
    url: '/platform/user-feedback-type/delete',
    method: 'post',
    data: data
  })
}
